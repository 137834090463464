import Vue from "vue";
import VueRouter, { Route, RouteConfig, RouteRecord } from "vue-router";
import { auth, log } from "@/global";

Vue.use(VueRouter);

const titleTagSeparator = " • ";
const titleTagPrefix = "Complaint Portal" + titleTagSeparator;
const titleTagSuffix = titleTagSeparator + "Geauga County Board of Revisions";

const routes: any = [
	{
		path: "/",
		name: "HomePage",
		component: () => import("@/views/HomePage.vue"),
		meta: {
			requiresAuth: true,
			title: titleTagPrefix + "Dashboard" + titleTagSuffix,
		},
	},
	{
		path: "/my-account",
		name: "MyAccountPage",
		component: () => import("@/views/MyAccountPage.vue"),
		meta: {
			requiresAuth: true,
			title: titleTagPrefix + "My Account" + titleTagSuffix,
		},
	},
	{
		path: "/complaint",
		component: () => import("@/views/NewComplaintPage.vue"),
		meta: {
			requiresAuth: true,
			title: titleTagPrefix + "New Complaint" + titleTagSuffix,
		},
	},
	{
		path: "/complaint/new",
		component: () => import("@/views/NewComplaintPage.vue"),
		meta: {
			requiresAuth: true,
			title: titleTagPrefix + "New Complaint" + titleTagSuffix,
		},
	},
	{
		path: "/complaint/counter/new",
		component: () => import("@/views/NewCounterComplaintPage.vue"),
		meta: {
			requiresAuth: true,
			title: titleTagPrefix + "New Counter Complaint" + titleTagSuffix,
		},
	},
	{
		path: "/complaint/search",
		component: () => import("@/views/ComplaintSearchPage.vue"),
		meta: {
			requiresAuth: true,
			title: titleTagPrefix + "Complaint Search" + titleTagSuffix,
		},
	},
	{
		path: "/complaint/:complaintId",
		name: "ComplaintDetailPage",
		component: () => import("@/views/ComplaintDetailPage.vue"),
		meta: {
			requiresAuth: true,
			title: titleTagPrefix + "Complaint Detail" + titleTagSuffix,
		},
	},
	{
		path: "/complaint/:complaintId/:mode",
		component: () => import("@/views/ComplaintDetailPage.vue"),
	},
	{
		path: "/how-to/:articleId",
		name: "HowToPage",
		component: () => import("@/views/HowToPage.vue"),
		meta: {
			requiresAuth: false,
		},
	},
	{
		path: "/manage",
		name: "ManageSettingsPage",
		component: () => import("@/views/ManagePage.vue"),
		meta: {
			requiresAuth: true,
		},
		children: [
			{
				path: "school-contacts",
				name: "SchoolContactsPage",
				component: () => import("@/views/SchoolContactsPage.vue"),
				meta: {
					requiresAuth: true,
				},
			},
		],
	},
	{
		path: "/disclaimers/:disclaimerId",
		name: "DisclaimersPage",
		component: () => import("@/views/DisclaimersPage.vue"),
	},
	{
		path: "/logout",
		name: "LogoutPage",
		component: () => import("@/views/LogoutPage.vue"),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/login",
		name: "LoginPage",
		component: () => import("@/views/LoginPage.vue"),
		meta: {
			title: titleTagPrefix + "Login" + titleTagSuffix,
		},
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.VUE_APP_PATH,
	routes,
});

router.beforeEach(async (to: Route, from: Route, next: Function) => {
	const requiresAuth = to.matched.some((record: RouteRecord) => record.meta.requiresAuth);

	if (requiresAuth && !(await auth.getCurrentUser())) {
		log("Page requires login, but user is not logged in");
		next({
			name: "LoginPage",
			params: { redirect: to.fullPath },
		});
	} else if ((to.name === "LoginPage" || to.name === "RegisterPage") && (await auth.getCurrentUser())) {
		//next("/");
		next();
	} else {
		next();
	}
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
	// This goes through the matched routes from last to first, finding the closest route with a title.
	// eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
	const nearestWithTitle = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.title);

	// Find the nearest route element with meta tags.
	const nearestWithMeta = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.metaTags);

	const previousNearestWithMeta = from.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.metaTags);

	// If a route with a title was found, set the document (page) title to that value.
	if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

	// Remove any stale meta tags from the document using the key attribute we set below.
	Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map((el: Element) => (!!el.parentNode ? el.parentNode.removeChild(el) : null));

	// Skip rendering meta tags if there are none.
	if (!nearestWithMeta) return next();

	// Turn the meta tag definitions into actual elements in the head.
	nearestWithMeta.meta.metaTags
		.map((tagDef: any) => {
			const tag = document.createElement("meta");

			Object.keys(tagDef).forEach((key) => {
				tag.setAttribute(key, tagDef[key]);
			});

			// We use this to track which meta tags we create, so we don't interfere with other ones.
			tag.setAttribute("data-vue-router-controlled", "");

			return tag;
		})
		// Add the meta tags to the document head.
		.forEach((tag: any) => (!!document.head ? document.head.appendChild(tag) : null));

	next();
});

export default router;

import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";
import { firebase, db } from "@/config/firebase";

Vue.use(Vuex);

import { data } from "@/global";

import { User, Complaint, ComplaintStatus, Download } from "@/data/interfaces";
import { UserRole } from "@/data/types";
import { ComplaintConverter, UserConverter } from "@/data/converters";

export default new Vuex.Store({
	state: {
		appStatus: "loading" as string,
		auth: {
			appUser: null as null | User,
			authUser: null as null | firebase.User,
		},
		complaintStatuses: [
			{ id: "created", name: "Created", color: "grey" },
			{ id: "in-progress", name: "In Progress", color: "blue" },
			{ id: "reviewed", name: "Reviewed", color: "orange" },
			{ id: "completed", name: "Completed", color: "green" },
			{ id: "closed", name: "Closed", color: "red" },
		] as Array<ComplaintStatus>,
		applications: [
			{
				title: "DTE 1 Board of Revision Complaint Form",
				shortTitle: "DTE 1 Form",
				description: "Application for complaint against the value of a parcel or a group of parcels.",
				url: "https://www.gcrealestate.com/forms/DTE-1.pdf",
				helpText: "Most commonly used application when filing a complaint. All forms must be notarized.",
			},
			/*{
				title: "DTE 1A Board of Revision Special Covid-19 Complaint Form",
				shortTitle: "DTE 1A Form",
				description: "Application for special Covid-19-related complaints against real property involving tax years 2020, 2021, and 2022.",
				url: "https://www.gcrealestate.com/forms/DTE-1A.pdf",
				helpText: "Use this form for Covid-19 complaints. All forms must be notarized.",
			},*/
			{
				title: "DTE 2 Board of Revision Complaint Other than Market Value Form",
				shortTitle: "DTE 2 Form",
				description: "Application for complaints regarding assessment issues other than the market value of property.",
				url: "https://www.gcrealestate.com/forms/DTE-2.pdf",
				helpText: "Used for CAUV complaints. All forms must be notarized.",
			},
		] as Array<Download>,
		complaints: [] as Array<Complaint>,
		appUsers: [] as Array<User>,
	},

	mutations: {
		SET_APP_STATUS(state, value: string) {
			state.appStatus = value;
		},

		SET_AUTH_USER(state, value: null | firebase.User) {
			state.auth.authUser = value;
		},

		SET_APP_USER(state, value: null | User) {
			state.auth.appUser = value;
		},

		ADD_COMPLAINT(state, value: Complaint) {
			state.complaints.push(value);
		},

		UPDATE_COMPLAINT(state, value: Complaint) {
			const foundComplaint = state.complaints.find((complaint: Complaint) => complaint.id === value.id) as Complaint;
			Object.assign(foundComplaint, value);
		},

		REMOVE_COMPLAINT(state, value: Complaint) {
			const index = state.complaints.findIndex((complaint: Complaint) => complaint.id === value.id);
			state.complaints.splice(index, 1);
		},

		RESET_COMPLAINT(state) {
			state.complaints = [] as Array<Complaint>;
		},
	},

	actions: {
		async loadApp({ commit, dispatch }) {
			dispatch("resetApp");

			commit("SET_APP_STATUS", "loading");

			await dispatch("bindComplaints");

			commit("SET_APP_STATUS", "ready");
		},

		handleAuth({ commit, dispatch }): Promise<void> {
			return new Promise((resolve, reject) => {
				commit("SET_APP_STATUS", "authenticating");

				firebase.auth().onAuthStateChanged(async (authUser: null | firebase.User) => {
					commit("SET_AUTH_USER", authUser);

					if (authUser) {
						try {
							// Let's look for a 'users' document who's ID matches the UID of the logged in user.
							const appUser = await data.user.get(authUser.uid);
							commit("SET_APP_USER", appUser);
							dispatch("loadApp");
						} catch (e) {
							let newUser = {
								email: authUser.email,
							} as User;

							const newUserId = await data.user.add(authUser.uid, newUser);
							const appUser = await data.user.get(newUserId);

							commit("SET_APP_USER", appUser);
							dispatch("loadApp");
						}
					} else {
						commit("SET_APP_USER", null);
						dispatch("loadApp");
					}

					resolve();
				});
			});
		},

		bindComplaints({ state, commit, dispatch }): Promise<number> {
			return new Promise(async (resolve, reject) => {
				if (!!state.auth.appUser) {
					let query = db.collection("complaints").where("archived", "==", false) as firebase.firestore.Query;

					if (state.auth.appUser.role !== "admin") {
						let totalCount = 0;

						const ownerQuery = query.where("owner", "==", state.auth.appUser!.id).withConverter(ComplaintConverter);
						const contactQuery = query.where("owner", "!=", state.auth.appUser!.id).where("group", "array-contains", state.auth.appUser!.email).withConverter(ComplaintConverter);
						const [ownerQuerySnapshot, contactQuerySnapshot] = await Promise.all([ownerQuery, contactQuery]);

						ownerQuerySnapshot.onSnapshot(async (snapshot: firebase.firestore.QuerySnapshot<Complaint>) => {
							totalCount += await dispatch("handleDocChanges", snapshot.docChanges());

							contactQuerySnapshot.onSnapshot(async (snapshot: firebase.firestore.QuerySnapshot<Complaint>) => {
								totalCount += await dispatch("handleDocChanges", snapshot.docChanges());

								resolve(totalCount);
							});
						});
					} else {
						query
							.orderBy("created", "asc")
							.withConverter(ComplaintConverter)
							.onSnapshot((snapshot: firebase.firestore.QuerySnapshot<Complaint>) => {
								resolve(dispatch("handleDocChanges", snapshot.docChanges()));
							});
					}
				} else {
					resolve(0);
				}
			});
		},

		handleDocChanges({ commit }, changes: Array<firebase.firestore.DocumentChange<Complaint>>): Promise<number> {
			return new Promise((resolve, reject) => {
				let count = 0;

				changes.forEach((change: firebase.firestore.DocumentChange<Complaint>) => {
					if (change.type === "added") {
						commit("ADD_COMPLAINT", change.doc.data());
					} else if (change.type === "modified") {
						commit("UPDATE_COMPLAINT", change.doc.data());
					} else if (change.type === "removed") {
						commit("REMOVE_COMPLAINT", change.doc.data());
					}

					count++;
				});

				resolve(count);
			});
		},

		async fetchUser({ state, commit }, user: null | User) {
			commit("SET_LOGGED_IN", user !== null);

			if (user) {
				commit("SET_USER", {
					displayName: user.firstName + " " + user.lastName,
					email: user.email,
				});
			} else {
				commit("SET_USER", null);
			}
		},

		resetApp({ commit, state }) {
			state.complaints.forEach((complaint: Complaint) => {
				commit("REMOVE_COMPLAINT", complaint);
			});
		},

		fetchUsers({ commit, state, getters }) {
			if (state.auth.appUser?.role === "admin") {
				db.collection("users")
					.where("role", "==", "appraiser" as UserRole)
					.orderBy("created", "asc")
					.withConverter(UserConverter)
					.get()
					.then((snapshot: firebase.firestore.QuerySnapshot<User>) => {
						snapshot.docs.forEach((doc: firebase.firestore.QueryDocumentSnapshot<User>) => {
							commit("addUser", doc.data());
						});
					})
					.catch((error: any) => {
						console.error(error);
					});

				db.collection("users")
					.where("role", "==", "admin" as UserRole)
					.orderBy("created", "asc")
					.withConverter(UserConverter)
					.get()
					.then((snapshot: firebase.firestore.QuerySnapshot<User>) => {
						snapshot.docs.forEach((doc: firebase.firestore.QueryDocumentSnapshot<User>) => {
							commit("addUser", doc.data());
						});
					})
					.catch((error: any) => {
						console.error(error);
					});
			}
		},
	},

	getters: {
		myComplaints(state): Array<Complaint> {
			return state.complaints.filter((complaint: Complaint) => complaint.owner === state.auth.appUser?.id || complaint.group.includes(state.auth.appUser!.email));
		},

		complaints(state): Array<Complaint> {
			return state.complaints.filter((complaint: Complaint) => complaint.owner !== state.auth.appUser?.id);
		},

		adminUsers(state): Array<User> {
			return state.appUsers.filter((user: User) => user.role === "admin");
		},

		appUser(state): null | User {
			return state.auth.appUser;
		},

		authUser(state): null | firebase.User {
			return state.auth.authUser;
		},

		intialStatus(state): ComplaintStatus {
			return state.complaintStatuses[0];
		},
	},
});

import { AppSetting, AuditLog, Complaint, User } from "@/data/interfaces";
import store from "@/store";
import firebase from "firebase";

export var ComplaintConverter = {
	toFirestore(complaint: Complaint) {
		return {
			type: complaint.type,
			uploads: complaint.uploads,
			archived: complaint.archived ? complaint.archived : false,
			created: complaint.created ? complaint.created : firebase.firestore.Timestamp.now(),
			comments: complaint.comments ? complaint.comments : "",
			owner: complaint.owner,
			status: complaint.status ? complaint.status : store.getters.intialStatus.id,
			lockedBy: complaint.lockedBy ? complaint.lockedBy : null,
			hearingDate: complaint.hearingDate ? complaint.hearingDate : null,
			finalReviewLetter: complaint.finalReviewLetter ? complaint.finalReviewLetter : null,
			updated: firebase.firestore.Timestamp.now(),
			eSignature: complaint.eSignature,
			contact: complaint.contact,
			group: complaint.group ? complaint.group : [complaint.contact.emailAddress],
			complaintNumber: !!complaint.complaintNumber ? complaint.complaintNumber : null,
			counterclaims: !!complaint.counterclaims ? complaint.counterclaims : [],
			letterOfIntent: !!complaint.letterOfIntent ? complaint.letterOfIntent : null,
			hearingNotice: !!complaint.hearingNotice ? complaint.hearingNotice : null,
			parcel: !!complaint.parcel ? complaint.parcel : null,
			description: !!complaint.description ? complaint.description : null,
		};
	},

	fromFirestore(snapshot: any, options: any) {
		const data = snapshot.data(options) as Complaint;
		return { ...data, id: snapshot.id } as Complaint;
	},
};

export var UserConverter = {
	toFirestore(user: User) {
		return {
			archived: !!user.archived ? user.archived : false,
			created: !!user.created ? user.created : firebase.firestore.Timestamp.now(),
			firstName: !!user.firstName ? user.firstName : "",
			lastName: !!user.lastName ? user.lastName : "",
			email: user.email,
			role: !!user.role ? user.role : "resident",
		};
	},

	fromFirestore(snapshot: firebase.firestore.DocumentSnapshot, options: firebase.firestore.SnapshotOptions) {
		const data = snapshot.data(options) as User;
		return { ...data, id: snapshot.id };
	},
};

export var AuditLogConverter = {
	toFirestore(auditLog: AuditLog) {
		return {
			timestamp: auditLog.timestamp,
			type: auditLog.type,
			text: auditLog.text,
			summary: auditLog.summary,
			dataType: auditLog.dataType,
			dataId: auditLog.dataId,
			userId: auditLog.userId,
			userName: auditLog.userName,
		};
	},

	fromFirestore(snapshot: any, options: any) {
		const data = snapshot.data(options) as AuditLog;
		return { ...data, id: snapshot.id };
	},
};

export var AppSettingConverter = {
	toFirestore(option: AppSetting) {
		return {
			key: option.key,
			value: option.value,
		};
	},

	fromFirestore(snapshot: any, options: any) {
		const data = snapshot.data(options) as AppSetting;
		return { ...data, id: snapshot.id };
	},
};

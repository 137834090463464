import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/vue";
import Vue from "vue";
import Vuelidate from "vuelidate";

Vue.use(Vuelidate);

Vue.config.productionTip = false;

const scripts = ["https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/js/bootstrap.bundle.min.js", "https://kit.fontawesome.com/b2bd102678.js"];

scripts.forEach((scriptUrl: string) => {
	const script = document.createElement("script");
	script.src = scriptUrl;
	script.async = false;
	document.head.appendChild(script);
});

Sentry.init({
	Vue,
	dsn: "https://c4b072cd0b004233b690f8566f1c3b79@o360256.ingest.sentry.io/4504634881540096",
	integrations: [
		new Sentry.Replay(),
		new BrowserTracing({
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
			tracePropagationTargets: ["localhost", "my-site-url.com", /^\//],
		}),
	],
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,
	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,
	logErrors: true,
	environment: process.env.NODE_ENV === "production" ? "production" : "development",
});

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");

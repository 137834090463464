
















































































import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import store from "@/store";
import TriviaQuestions from "@/assets/static/trivia.json";
import moment from "moment";
import project from "../package.json";

// Data Interfaces
import { TriviaQuestion } from "@/data/interfaces";

export default Vue.extend({
	data() {
		return {
			appDetails: project,
			triviaQuestion: {} as TriviaQuestion,
		};
	},

	async mounted() {
		this.triviaQuestion = this.getRandomTriviaQuestion();

		await store.dispatch("handleAuth");
	},

	methods: {
		getRandomTriviaQuestion(): TriviaQuestion {
			return TriviaQuestions[Math.floor(Math.random() * TriviaQuestions.length)];
		},
	},

	computed: {
		...mapState({ appStatus: "appStatus" }),

		...mapGetters({ authUser: "authUser", appUser: "appUser" }),

		isAppReady(): boolean {
			return this.appStatus === "ready";
		},

		thisYear(): string {
			return moment().format("YYYY");
		},

		welcomeMsg(): null | string {
			let message = "Welcome back!";

			if (!!this.appUser) {
				if (!!this.appUser.firstName && !!this.appUser.lastName) {
					message = "Welcome back, " + this.appUser.firstName + " " + this.appUser.lastName + "!";
				} else if (!!this.appUser.name) {
					message = "Welcome back, " + this.appUser.name + "!";
				}
			}

			return message;
		},
	},

	watch: {},
});
